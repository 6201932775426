import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { FeatureService } from 'src/app/shared/services/feature.service';

@Injectable({
    providedIn: 'root',
})
export class FeatureGuardService  {
    constructor(
        private featureService: FeatureService,
        private router: Router
    ) {}
    async canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean | UrlTree> {
        await this.getAllFeatures();
        let featureName = route.data?.featureName;
        if (featureName) {
            if (this.featureService.checkAccess(featureName)) {
                return true;
            } else {
                this.router.navigate(['/home']);
                return false;
            }
        } else {
            return true;
        }
    }

    async canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean | UrlTree> {
        await this.getAllFeatures();

        if (childRoute.data?.featureName) {
            if (this.featureService.checkAccess(childRoute.data?.featureName)) {
                return true;
            } else {
                this.router.navigate(['/home']);
                return false;
            }
        } else {
            return true;
        }
    }

    async getAllFeatures() {
        await this.featureService.getAllFeatures();
    }
}

export const FeatureGuard: CanActivateFn = (
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    return inject(FeatureGuardService).canActivate(next, state);
};
